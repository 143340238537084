<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div> <h1> <strong>FICHE D'ENREGISTREMENT DES CLIENTS DES HÔTELS</strong> </h1>  </div>
        </b-col>
      </b-row>
    </div>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> RENSEIGNEMENTS GÉNÉRAUX </strong>
        </b-col>
      </b-row>
    </div>

    <b-card-body class=" nopadding">
      <div class="pl-1 pr-1 pt-1">
        <b-row>
          <b-col
            md="6"
            class=""
          >
            Établissement: <strong> {{ validOutput.npi }} </strong>
          </b-col>
          <b-col
            md="6"
            class=""
          >
            Compagnie aérienne: <strong> {{ validOutput.compagnieAerienne }} </strong>
          </b-col>
          <b-col
            md="6"
            class=""
          >
            NPI: <strong> {{ validOutput.npi }} </strong>
          </b-col>

          <b-col
            md="4"
            class=" "
          >
            Date d'arrivée: <strong> {{ validOutput.dateArrivee }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-center"
          >
            Heure: <strong> {{ validOutput.heureArrivee }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Date de départ: <strong> {{ validOutput.dateDepart }} </strong>
          </b-col>

          <b-col md="2" />
          <b-col md="2" />

          <!-- <b-col md="4">
            Heure: <strong> {{ validOutput.heureArrivee }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Chambre N° <strong> {{ validOutput.numeroChambre }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Date de départ: <strong> {{ validOutput.dateDepart }} </strong>
          </b-col>
          <b-col md="2" /> -->
        </b-row>
      </div>
    </b-card-body>

    <!-- renseignement generaux -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> DÉTAILS  </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            Nom: <strong> {{ validOutput.lastname }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Age: <strong> {{ validOutput.age }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Nom de la jeune fille: <strong> {{ validOutput.maidenname }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Sexe: <strong> {{ validOutput.sexe }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Date de naissance: <strong> {{ validOutput.dateNaissance }} </strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Lieu de naissance: <strong> {{ validOutput.lieuNaissance }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Nationalité: <strong> {{ validOutput.nationalite }} </strong>
          </b-col>
          <hr>
        </b-row>
      </div>
    </b-card-body>


    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> PIÈCE D'IDENTITÉ </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <div v-if="validOutput.pieceIdentite">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            Pièce d'identité: <strong> {{ validOutput.pieceIdentite.type }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Numero de la pièce: <strong> {{ validOutput.pieceIdentite.numeroPiece }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Délivrée par: <strong> {{ validOutput.pieceIdentite.delivrePar }} </strong>
          </b-col>
          <!-- <b-col
            md="12"
            class="mb-1"
          >
            Le: <strong> {{ validOutput.pieceIdentite.delivrePar }} </strong>
          </b-col> -->
          <b-col
            md="12"
            class="mb-1"
          >
            À: <strong> {{ validOutput.pieceIdentite.delivrePar }} </strong>
          </b-col>

        </b-row>
      </div>
    </b-card-body>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> LIEU DE RÉSIDENCE HABITUEL </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <div v-if="validOutput.lieuResidence">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            Adresse: <strong> {{ validOutput.lieuResidence.adresse }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Code Postal: <strong> {{ validOutput.lieuResidence.bp }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Ville/Commune: <strong> {{ validOutput.lieuResidence.ville }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Département: <strong> {{ validOutput.lieuResidence.departement }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Pays: <strong> {{ validOutput.lieuResidence.pays }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Téléphone: <strong> {{ validOutput.telephone }} </strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            Mail <strong> {{ validOutput.email }} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> AUTRES INFORMATIONS </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <div>
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            Catégorie Socio-Professionnelle: <strong> {{ validOutput.categorieSocioPro }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Mode principal de transport à l'intérieur du pays: <strong> {{ validOutput.modeTransportInterieurPays }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Motif principal de voyage: <strong> {{ validOutput.motifPrincipalVoyage }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Allez-vous percevoir une rémuneration d'une entité du pays/ lieu visité durant votre séjour ?: <strong> {{ validOutput.possibiliteRemuneration }} </strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            Signature <strong> {{ validOutput.signature }} </strong>
          </b-col>
        </b-row>
        <hr>
      </div>
    </b-card-body>
    <!-- <hr class="invoice-spacing mt-5"> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  data() {
    return {
    }
  },

  mounted() {
    // this.validOutput.agrementMinistere = 'non'
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background{
  background-color: #ababab;
}

.tableHeadFontSize{
  font-size: 10px;
}
</style>
